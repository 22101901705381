//@ts-nocheck
import { Currency } from 'constants/currency';
import { DocumentType } from 'constants/documentType';
import { Gender } from 'constants/gender';
import { IKBErrorCode } from 'constants/ikbErrorCode';
import commonMessages from 'messages/CommonMessages';
import loyaltyErrorMessages from 'messages/LoyaltyErrorMessages';
import documentListPageMessages from 'page/documents/DocumentListPageMessages';

export function getGenderMessage(gender) {
    switch (gender) {
        case Gender.MALE:
            return commonMessages.genderMale;
        case Gender.FEMALE:
            return commonMessages.genderFemale;
        default:
            return commonMessages.genderUnknown;
    }
}

export function getFreeCallText(intl, territory) {
    let territoryMessage;

    switch (territory) {
        case 'RUSSIA':
        case 'CRIMEA':
            territoryMessage = commonMessages.inRussia;
            break;
        case 'BELARUS':
            territoryMessage = commonMessages.inBelarus;
            break;
        case 'KAZAKHSTAN':
            territoryMessage = commonMessages.inKazakhstan;
            break;
        case 'UKRAINE':
            territoryMessage = commonMessages.inUkraine;
            break;
        case 'ARMENIA':
            territoryMessage = commonMessages.inArmenia;
            break;
        case 'KYRGYZSTAN':
            territoryMessage = commonMessages.inKyrgyzstan;
            break;
        default:
            territoryMessage = null;
            break;
    }

    return intl.formatMessage(commonMessages.freeCall, { territoryName: territoryMessage ? intl.formatMessage(territoryMessage) : '' });
}

export function getLoyaltyErrorText(intl, errorCode, defaultMessage) {
    switch (errorCode) {
        case IKBErrorCode.ACCOUNT_NOT_FOUND:
            return intl.formatMessage(loyaltyErrorMessages.ACCOUNT_NOT_FOUND);
        case IKBErrorCode.ACCOUNT_BLOCKED:
            return intl.formatMessage(loyaltyErrorMessages.ACCOUNT_BLOCKED);
        case IKBErrorCode.ACCOUNT_DEACTIVATED:
            return intl.formatMessage(loyaltyErrorMessages.ACCOUNT_DEACTIVATED);
        case IKBErrorCode.CONTACT_DEACTIVATED:
            return intl.formatMessage(loyaltyErrorMessages.CONTACT_DEACTIVATED);
        case IKBErrorCode.CONTACT_NO_MATCH_ACCOUNT:
            return intl.formatMessage(loyaltyErrorMessages.CONTACT_NO_MATCH_ACCOUNT);
        case IKBErrorCode.CREATE_ACCOUNT_NOT_ALLOWED:
            return intl.formatMessage(loyaltyErrorMessages.CREATE_ACCOUNT_NOT_ALLOWED);
        case IKBErrorCode.ACCOUNT_STATUS_ACTIVE:
            return intl.formatMessage(loyaltyErrorMessages.ACCOUNT_STATUS_ACTIVE);
        case IKBErrorCode.ACCOUNT_ALREADY_ACTIVATED:
            return intl.formatMessage(loyaltyErrorMessages.ACCOUNT_ALREADY_ACTIVATED);
        case IKBErrorCode.CONTACT_WRONG_PHONE:
            return intl.formatMessage(loyaltyErrorMessages.CONTACT_WRONG_PHONE);
        case IKBErrorCode.PIN_SEND_TIMEOUT_NOT_EXPIRED:
            return intl.formatMessage(loyaltyErrorMessages.PIN_SEND_TIMEOUT_NOT_EXPIRED);
        case IKBErrorCode.PIN_AWAITING_TIME_EXPIRED:
            return intl.formatMessage(loyaltyErrorMessages.PIN_AWAITING_TIME_EXPIRED);
        case IKBErrorCode.WRONG_PIN:
            return intl.formatMessage(loyaltyErrorMessages.WRONG_PIN);
        case IKBErrorCode.PIN_NOT_SENT:
            return intl.formatMessage(loyaltyErrorMessages.PIN_NOT_SENT);
        default:
            return defaultMessage ? defaultMessage : intl.formatMessage(loyaltyErrorMessages.UNKNOWN_ERROR);
    }
}

export function getCurrencyText(intl, currency) {
    switch (currency) {
        case Currency.RUB:
        case Currency.RUR:
        case Currency.BYR:
        case Currency.BYN:
            return intl.formatMessage(commonMessages.ruble);
        case Currency.KZT:
            return intl.formatMessage(commonMessages.tenge);
        case Currency.UAH:
            return intl.formatMessage(commonMessages.hryvnia);
        case Currency.AMD:
            return intl.formatMessage(commonMessages.dram);
        case Currency.KGS:
            return intl.formatMessage(commonMessages.som);
        default:
            return '';
    }
}

export function getDocumentName(intl, type) {
    switch (type) {
        case DocumentType.AGREEMENT_BLANK_TYPE_GENERAL:
            return intl.formatMessage(documentListPageMessages.AGREEMENT_BLANK_TYPE_GENERAL);
        case DocumentType.AGREEMENT_BLANK_TYPE_AIDS:
            return intl.formatMessage(documentListPageMessages.AGREEMENT_BLANK_TYPE_AIDS);
        case DocumentType.AGREEMENT_BLANK_TYPE_CONSENT:
            return intl.formatMessage(documentListPageMessages.AGREEMENT_BLANK_TYPE_CONSENT);
        case DocumentType.AGREEMENT_BLANK_TYPE_INTERVENTION:
            return intl.formatMessage(documentListPageMessages.AGREEMENT_BLANK_TYPE_INTERVENTION);
        case DocumentType.AGREEMENT_BLANK_TYPE_LOYALTY:
            return intl.formatMessage(documentListPageMessages.AGREEMENT_BLANK_TYPE_LOYALTY);
        case DocumentType.AGREEMENT_BLANK_TYPE_LOYALTY_DENIAL:
            return intl.formatMessage(documentListPageMessages.AGREEMENT_BLANK_TYPE_LOYALTY_DENIAL);
        case DocumentType.MAIN_FORM:
            return intl.formatMessage(documentListPageMessages.MAIN_FORM);
        case DocumentType.ANTIGEN_TEST:
            return intl.formatMessage(documentListPageMessages.ANTIGEN_TEST);
        case DocumentType.CYTOLOGY:
            return intl.formatMessage(documentListPageMessages.CYTOLOGY);
        case DocumentType.ECOLOGY:
            return intl.formatMessage(documentListPageMessages.ECOLOGY);
        case DocumentType.FEMOFLOR:
            return intl.formatMessage(documentListPageMessages.FEMOFLOR);
        case DocumentType.GENETICS:
            return intl.formatMessage(documentListPageMessages.GENETICS);
        case DocumentType.HISTOLOGY:
            return intl.formatMessage(documentListPageMessages.HISTOLOGY);
        case DocumentType.INTERFERON_STATUS:
            return intl.formatMessage(documentListPageMessages.INTERFERON_STATUS);
        case DocumentType.MICRIBIOLOGY:
            return intl.formatMessage(documentListPageMessages.MICRIBIOLOGY);
        case DocumentType.PCR:
            return intl.formatMessage(documentListPageMessages.PCR);
        case DocumentType.STUDIES_OF_TRACE_ELEMENTS:
            return intl.formatMessage(documentListPageMessages.STUDIES_OF_TRACE_ELEMENTS);
        case DocumentType.MICROSCOPIC_STUDY_ON_FUNGAL_CELLS:
            return intl.formatMessage(documentListPageMessages.MICROSCOPIC_STUDY_ON_FUNGAL_CELLS);
        case DocumentType.PCR_DIGENE_TEST:
            return intl.formatMessage(documentListPageMessages.PCR_DIGENE_TEST);
        case DocumentType.FIBROMAX:
            return intl.formatMessage(documentListPageMessages.FIBROMAX);
        case DocumentType.ANTROPOMETRY:
            return intl.formatMessage(documentListPageMessages.ANTROPOMETRY);
        case DocumentType.GLUCOSE_TUBES_OF_GRAY:
            return intl.formatMessage(documentListPageMessages.GLUCOSE_TUBES_OF_GRAY);
        case DocumentType.ECOLOGY_HEIGHT_TECHNOLOGY_STUDY_OF_WATER:
            return intl.formatMessage(documentListPageMessages.ECOLOGY_HEIGHT_TECHNOLOGY_STUDY_OF_WATER);
        case DocumentType.ECOLOGY_SCREENING_CHEMICAL_STUDY_OF_WATER:
            return intl.formatMessage(documentListPageMessages.ECOLOGY_SCREENING_CHEMICAL_STUDY_OF_WATER);
        case DocumentType.ECOLOGY_RADIONUCLIDE_ANALYSIS_OF_WATER:
            return intl.formatMessage(documentListPageMessages.ECOLOGY_RADIONUCLIDE_ANALYSIS_OF_WATER);
        case DocumentType.ECOLOGY_SOIL:
            return intl.formatMessage(documentListPageMessages.ECOLOGY_SOIL);
        case DocumentType.AIDS:
            return intl.formatMessage(documentListPageMessages.AIDS);
        case DocumentType.MOLECULAR_GENETIC:
            return intl.formatMessage(documentListPageMessages.MOLECULAR_GENETIC);
        case DocumentType.VOLUNTARY:
            return intl.formatMessage(documentListPageMessages.VOLUNTARY);
        case DocumentType.PRISCA:
            return intl.formatMessage(documentListPageMessages.PRISCA);
        case DocumentType.ENCEPHALITIS:
            return intl.formatMessage(documentListPageMessages.ENCEPHALITIS);
        case DocumentType.SURVEYS:
            return intl.formatMessage(documentListPageMessages.SURVEYS);
        case DocumentType.SPERMOGRAMM:
            return intl.formatMessage(documentListPageMessages.SPERMOGRAMM);
        case DocumentType.ESTIMATE:
            return intl.formatMessage(documentListPageMessages.ESTIMATE);
        case DocumentType.REFUND_ACT:
            return intl.formatMessage(documentListPageMessages.REFUND_ACT);
        default:
            return type;
    }
}
