import { useState } from 'react';

declare const window: any;
type Config = { reCaptchaV3SiteKey: string; reCaptchaV3Enable: boolean; locale: string };

const useRecaptcha = () => {
    const [token, setToken] = useState<string | null>(null);
    const [error, setError] = useState<boolean | string>(false);
    const [config, setConfig] = useState<Config>();
    const [ready, setReady] = useState(false);
    const SCRIPT_ID = 'captchaV3_script';

    const init = ({ reCaptchaV3SiteKey, reCaptchaV3Enable, locale }: Config) => {
        if (reCaptchaV3SiteKey && reCaptchaV3Enable && locale) {
            if (!ready && !document.getElementById(SCRIPT_ID)) {
                setConfig({ locale, reCaptchaV3Enable, reCaptchaV3SiteKey });
                return new Promise((resolve, reject) => {
                    if (!reCaptchaV3Enable) {
                        return null;
                    }
                    const script = document.createElement('script');
                    script.id = SCRIPT_ID;
                    script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaV3SiteKey}&hl=${locale}`;
                    script.async = true;
                    script.defer = true;
                    document.body.appendChild(script);
                    script.onload = () => {
                        resolve(true);
                        setReady(true);
                    };
                    script.onerror = (event) => {
                        reject(event);
                    };
                });
            } else {
                setBadgeLanguage(locale);
            }
        }
    };

    const setBadgeLanguage = (locale: string) => {
        window.grecaptcha &&
            window.grecaptcha.ready(() => {
                const badgeIframe = document.querySelector('.grecaptcha-badge .grecaptcha-logo iframe') as HTMLIFrameElement;
                if (badgeIframe) {
                    const badgeIframeSrc = new URL(badgeIframe.src);
                    badgeIframeSrc.searchParams.set('hl', locale);
                    badgeIframe.src = badgeIframeSrc.href;
                }
            });
    };

    const clear = () => {
        const existentScript = document.getElementById(SCRIPT_ID);
        if (existentScript) {
            document.body.removeChild(existentScript);
            delete window.grecaptcha;
            const scripts = document.getElementsByTagName('head')[0].getElementsByTagName('script');
            for (let i = 0; i < scripts.length; i++) {
                if (scripts[i].src.includes('recaptcha')) {
                    scripts[i].remove();
                }
            }
        }
        document.body.querySelector('.grecaptcha-badge')?.remove();
    };

    const execute = async () => {
        const grecaptcha = window.grecaptcha;
        if (!grecaptcha || !config?.reCaptchaV3Enable) return undefined;
        return new Promise((resolve, reject) => {
            grecaptcha &&
                grecaptcha.ready(() => {
                    grecaptcha
                        .execute(config?.reCaptchaV3SiteKey)
                        .then((token: string) => {
                            setToken(token);
                            setError(false);
                            resolve(token);
                        })
                        .catch((error: string) => {
                            setToken(null);
                            setError(error);
                            reject(error);
                        });
                });
        });
    };

    return {
        clear: () => {
            setReady(false);
            setToken(null);
            setError(false);
            clear();
        },
        error: error,
        execute: () => execute(),
        init: ({ reCaptchaV3SiteKey, reCaptchaV3Enable, locale }: Config) => init({ locale, reCaptchaV3Enable, reCaptchaV3SiteKey }),
        token: token
    };
};

export default useRecaptcha;
