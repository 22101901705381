//@ts-nocheck
import AlertWarning from 'components/alert/AlertWarning';
import commonMessages from 'messages/CommonMessages';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

type OnValidCallback = (value: any, name: string) => void;

export default class FormHelper {
    constructor(state, setState, error, validators) {
        this.state = state;
        this.setState = setState;
        this.error = error;
        this.validators = validators || {};
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    handleValueChange(object, onValidCallback: OnValidCallback | null = null, validate = true) {
        const target = object.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const valid = validate && this.validateField(target.name, value);
        this.setState((prevState) => ({ ...prevState, [target.name]: value }));
        if (valid && onValidCallback && typeof onValidCallback === 'function') {
            onValidCallback(value, target.name);
        }
    }

    validateField(field, value, errorsHolder = null) {
        const validator = this.validators[field];
        let isValid;
        if (validator) {
            isValid = validator(value);
        } else {
            isValid = true;
        }
        // Если передан holder для ошибок, то не нужно доставать его из state
        // так как вызывающая функция сама займётся обновлением состояния
        const errors = errorsHolder || { ...(this.state.errors || {}) };
        if (isValid) {
            delete errors[field];
        } else {
            errors[field] = true;
        }
        if (!errorsHolder) {
            this.setState((prevState) => ({ ...prevState, errors }));
        }
        return isValid;
    }

    validate(fields) {
        let isValid = true;
        const errorsHolder = {};
        fields.forEach((field) => {
            const isFieldValid = this.validateField(field, this.state[field], errorsHolder);
            isValid = isFieldValid && isValid;
        });

        this.setState((prevState) => ({ ...prevState, errors: errorsHolder }));
        return isValid;
    }

    isValid(fields) {
        return fields.map((field) => !this.validators[field] || this.validators[field](this.state[field])).reduce((acc, isValid) => acc && !!isValid, true);
    }

    renderError() {
        return this.error ? <AlertWarning content={this.renderErrorMessage()} timeToResend={this.error?.timeToResend} /> : null;
    }

    renderErrorMessage(errors) {
        const error = errors || this.error;

        if (error?.errors && error.errors.length !== 0) {
            const errors = error.errors;
            return errors.map((error) => {
                return (
                    <Fragment key={error.message}>
                        {error.message}
                        <br />
                    </Fragment>
                );
            });
        } else if (error?.message) {
            return error.message;
        } else {
            return <FormattedMessage {...commonMessages.serverError} />;
        }
    }

    isValidReCaptchaV3(enable, code) {
        return (enable && code?.length > 0) || !enable;
    }
}
