export const LOCALE_KEY_COOKIE = `INVITRO_LOCALE`;
export const LANGUAGE_HEADER = `accept-language`;

export const SUPPORTED_LOCALES = {
    ENGLISH: 'en',
    RUSSIAN: 'ru'
    //UKRAINIAN: 'uk'
};

export const LOCALES_BY_DOMAIN = {
    by: SUPPORTED_LOCALES.RUSSIAN,
    kz: SUPPORTED_LOCALES.RUSSIAN,
    ru: SUPPORTED_LOCALES.RUSSIAN,
    su: SUPPORTED_LOCALES.RUSSIAN,
    ua: SUPPORTED_LOCALES.RUSSIAN
};
