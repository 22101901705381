import { defineMessages } from 'react-intl';

export default defineMessages({
    addPatient: {
        defaultMessage: 'Add patient',
        id: 'Common.addPatient'
    },
    addPatientTitle: {
        defaultMessage: 'Add patient information to place your order',
        id: 'Common.addPatientTitle'
    },
    addResults: {
        defaultMessage: 'Add results',
        id: 'Common.addResults'
    },
    apply: {
        defaultMessage: 'Apply',
        id: 'Common.apply'
    },
    back: {
        defaultMessage: 'Back',
        id: 'Common.back'
    },
    genderFemale: {
        defaultMessage: 'Female',
        id: 'Common.genderFemale'
    },
    confirm: {
        defaultMessage: 'Confirm',
        id: 'Common.confirm'
    },
    genderMale: {
        defaultMessage: 'Male',
        id: 'Common.genderMale'
    },
    download: {
        defaultMessage: 'Download',
        id: 'Common.download'
    },
    genderUnknown: {
        defaultMessage: 'Unknown',
        id: 'Common.genderUnknown'
    },
    newOrder: {
        defaultMessage: 'New order',
        id: 'Common.newOrder'
    },
    ok: {
        defaultMessage: 'OK',
        id: 'Common.ok'
    },
    email: {
        defaultMessage: 'E-mail',
        id: 'Common.email'
    },
    print: {
        defaultMessage: 'print',
        id: 'Common.print'
    },
    emailPlaceholder: {
        defaultMessage: 'yournickname@domain.com',
        id: 'Common.emailPlaceholder'
    },
    resend: {
        defaultMessage: 'Send again',
        id: 'Common.resend'
    },
    formattedNumber: {
        defaultMessage: '#{number}',
        id: 'Common.formattedNumber'
    },
    send: {
        defaultMessage: 'Send',
        id: 'Common.send'
    },
    completed: {
        defaultMessage: 'Completed',
        id: 'Common.completed'
    },
    serverError: {
        id: 'Common.serverError',
        defaultMessage: 'unable to get response from server'
    },
    language: {
        defaultMessage: 'Language',
        id: 'Common.language'
    },
    firstNameLabel: {
        defaultMessage: 'Name',
        id: 'Common.firstNameLabel'
    },
    save: {
        id: 'Common.save',
        defaultMessage: 'Save'
    },
    firstNamePlaceholder: {
        defaultMessage: 'John',
        id: 'Common.firstNamePlaceholder'
    },
    valuesBeyondReference: {
        defaultMessage: '* values beyond reference values',
        id: 'Common.valuesBeyondReference'
    },
    lastNameLabel: {
        defaultMessage: 'Last name',
        id: 'Common.lastNameLabel'
    },
    view: {
        id: 'Common.view',
        defaultMessage: 'View'
    },
    lastNamePlaceholder: {
        defaultMessage: 'Smith',
        id: 'Common.lastNamePlaceholder'
    },
    inzNumberFormat: {
        defaultMessage: 'INZ {inz}',
        id: 'Common.inzNumberFormat'
    },
    sendByEmail: {
        id: 'Common.sendByEmail',
        defaultMessage: 'Send by e-mail'
    },
    birthdayLabel: {
        defaultMessage: 'Birth date',
        id: 'Common.birthdayLabel'
    },
    phone: {
        defaultMessage: 'Phone',
        id: 'Common.phone'
    },
    birthdayLabelFormat: {
        defaultMessage: 'Birth date: {birthday}',
        id: 'Common.birthdayLabelFormat'
    },
    phonePlaceholder: {
        id: 'Common.phonePlaceholder',
        defaultMessage: '+12345678901'
    },
    genderLabel: {
        defaultMessage: 'Gender',
        id: 'Common.genderLabel'
    },
    notCompleted: {
        defaultMessage: 'Not completed',
        id: 'Common.notCompleted'
    },
    freeCall: {
        defaultMessage: 'Free call in {territoryName}',
        id: 'Common.freeCall'
    },
    sendEmailSuccess: {
        id: 'Common.sendEmailSuccess',
        defaultMessage: 'Email sent successfully'
    },
    genderLabelFormat: {
        defaultMessage: 'Gender: {gender}',
        id: 'Common.genderLabelFormat'
    },
    retry: {
        id: 'Common.retry',
        defaultMessage: 'Retry'
    },
    inArmenia: {
        defaultMessage: 'Armenia',
        id: 'Common.inArmenia'
    },
    middleNameLabel: {
        defaultMessage: 'Middle name',
        id: 'Common.middleNameLabel'
    },
    inBelarus: {
        defaultMessage: 'Belarus',
        id: 'Common.inBelarus'
    },
    middleNamePlaceholder: {
        id: 'Common.middleNamePlaceholder',
        defaultMessage: '-'
    },
    acceptPersonalAgreement: {
        defaultMessage: ' and accept <link>personal agreement</link>',
        id: 'Common.acceptPersonalAgreement'
    },
    acceptPersonalAgreementBelarus: {
        defaultMessage: ' and give <link>consent</link> to the processing of personal data',
        id: 'Common.acceptPersonalAgreementBelarus'
    },
    officeNameFormat: {
        id: 'Common.officeNameFormat',
        defaultMessage: 'Medical office {office}'
    },
    acceptUserAgreement: {
        defaultMessage: 'Accept <link>user agreement</link>',
        id: 'Common.acceptUserAgreement'
    },
    inKazakhstan: {
        defaultMessage: 'Kazakhstan',
        id: 'Common.inKazakhstan'
    },
    discountProgram: {
        defaultMessage: 'discount program',
        id: 'Common.discountProgram'
    },
    login: {
        defaultMessage: 'Login',
        id: 'Common.login'
    },
    bonusProgram: {
        defaultMessage: 'your status in the bonus program',
        id: 'Common.bonusProgram'
    },
    resultsConsultationWarning: {
        id: 'Common.resultsConsultationWarning',
        defaultMessage: 'The results of research are not a diagnosis, you need to consult a specialist.'
    },
    bonusHint: {
        defaultMessage: 'The bonuses you can use when forming the order. 1 bonus = 1 {currency}',
        id: 'Common.bonusHint'
    },
    bonusScore: {
        defaultMessage: 'active balance',
        id: 'Common.bonusScore'
    },
    inRussia: {
        id: 'Common.inRussia',
        defaultMessage: 'Russia'
    },
    bonusScoreUnit: {
        defaultMessage: '{points, plural, one {# point} other {# point}}',
        id: 'Common.bonusScoreUnit'
    },
    address: {
        defaultMessage: 'Address',
        id: 'Common.address'
    },
    inKyrgyzstan: {
        defaultMessage: 'Kyrgyzstan',
        id: 'Common.inKyrgyzstan'
    },
    addressPlaceholder: {
        defaultMessage: 'Brannan st. 10, apt. 5',
        id: 'Common.addressPlaceholder'
    },
    inUkraine: {
        id: 'Common.inUkraine',
        defaultMessage: 'Ukraine'
    },
    employeeDiscountNominal: {
        defaultMessage: 'Discount amount {percent}%',
        id: 'Common.employeeDiscountNominal'
    },
    employeeDiscountProgram: {
        defaultMessage: 'employee discount',
        id: 'Common.employeeDiscount'
    },
    fullName: {
        defaultMessage: 'Full name',
        id: 'Common.fullName'
    },
    choose: {
        defaultMessage: 'Choose',
        id: 'Common.choose'
    },
    fullNamePlaceholder: {
        defaultMessage: 'John Smith',
        id: 'Common.fullNamePlaceholder'
    },
    increaseCashback: {
        defaultMessage: 'Increase cashback',
        id: 'Common.increaseCashback'
    },
    individualServices: {
        defaultMessage: 'Individual services',
        id: 'Common.individualServices'
    },
    activateCashback: {
        defaultMessage: 'Activate cashback',
        id: 'Common.activateCashback'
    },
    notConnectedLoyalty: {
        defaultMessage: 'You are not a member of the loyalty program',
        id: 'Common.notConnectedLoyalty'
    },
    IKBNoticeRules: {
        defaultMessage: '<span>By clicking the activate button,</span> you accept the <link>program rules</link>',
        id: 'Common.IKBNoticeRules'
    },
    statusHint: {
        id: 'Common.bonusStatusHint',
        defaultMessage: 'Bonus program membership status. In accordance with status different amount of bonuses will be accrued.'
    },
    IKBCashbackTitle: {
        defaultMessage: 'Cashback',
        id: 'Common.IKBCashbackTitle'
    },
    subscribe: {
        id: 'Common.subscribe',
        defaultMessage: 'I agree to receive promotional and informational messages'
    },
    cashbackActivation: {
        defaultMessage: 'Cashback activation',
        id: 'Common.cashbackActivation'
    },
    needFillContactDetails: {
        defaultMessage: 'Patient contact information must be filled',
        id: 'Common.needFillContactDetails'
    },
    otherProductsNotice: {
        defaultMessage: 'Other tests not included in the category',
        id: 'Common.otherProductsNotice'
    },
    hryvnia: {
        defaultMessage: 'hryvnia',
        id: 'Common.hryvnia'
    },
    readySolutions: {
        id: 'Common.readySolutions',
        defaultMessage: 'Ready solutions'
    },
    dram: {
        defaultMessage: 'dram',
        id: 'Common.dram'
    },
    regularCashback: {
        id: 'Common.regularCashback',
        defaultMessage: 'Regular cashback'
    },
    notAllowedLoyalty: {
        defaultMessage:
            'You don’t have access to loyalty program at the moment. To get access, please, request confirmation in "Patients" module and activate "Allow to use loyalty program" option.',
        id: 'Common.notAllowedLoyalty'
    },
    invitro: {
        defaultMessage: 'INVITRO',
        id: 'Common.invitro'
    },
    otherServices: {
        id: 'Common.otherServices',
        defaultMessage: 'Other services'
    },
    feedback: {
        defaultMessage: 'Feedback',
        id: 'Common.feedback'
    },
    noticeSendCode: {
        defaultMessage: 'You can send the code again via <minutes></minutes> m <seconds></seconds> s',
        id: 'Common.noticeSendCode'
    },
    buttonConfirmSelection: {
        defaultMessage: 'Confirm selection',
        id: 'Common.buttonConfirmSelection'
    },
    personalArea: {
        id: 'Common.personalArea',
        defaultMessage: 'Personal area'
    },
    buttonContinue: {
        defaultMessage: 'Continue',
        id: 'Common.buttonContinue'
    },
    numberFormat: {
        defaultMessage: '# {number}',
        id: 'Common.numberFormat'
    },
    enterEmail: {
        defaultMessage: 'Enter e-mail',
        id: 'Common.enterEmail'
    },
    onlineRegistrationWarning: {
        defaultMessage:
            'Attention! Before printing documents at the online registration counter, please contact your office administrator for temperature measurements. In order to avoid the spread of coronavirus infection, we do not provide services in the Invitro medical offices if your temperature is above 37.5.',
        id: 'Common.onlineRegistrationWarning'
    },
    approvalData: {
        defaultMessage: 'Fill in the details below to increase the likelihood of installment approval:',
        id: 'Common.approvalData'
    },
    ruble: {
        defaultMessage: 'ruble',
        id: 'Common.ruble'
    },
    emailForCheck: {
        defaultMessage: 'E-mail for sending the check',
        id: 'Common.emailForCheck'
    },
    tenge: {
        id: 'Common.tenge',
        defaultMessage: 'tenge'
    },
    ivitro: {
        defaultMessage: 'IVITRO',
        id: 'Common.ivitro'
    },
    couponNotApplicable: {
        defaultMessage: 'Coupon not applicable',
        id: 'Common.couponNotApplicable'
    },
    som: {
        id: 'Common.som',
        defaultMessage: 'som'
    },
    certificateNotApplicable: {
        defaultMessage: 'Certificate not applicable',
        id: 'Common.certificateNotApplicable'
    },
    phoneFormatError: {
        id: 'Common.phoneFormatError',
        defaultMessage: 'Enter the phone number in the format {formatPhone}'
    },
    chat: {
        defaultMessage: 'Chat',
        id: 'Common.chat'
    },
    call: {
        defaultMessage: 'Call',
        id: 'Common.call'
    },
    no: {
        defaultMessage: 'No',
        id: 'Common.no'
    },
    callToCallCenter: {
        defaultMessage: 'Call to the call center',
        id: 'Common.callToCallCenter'
    },
    paymentPhoneNotice: {
        defaultMessage: 'Make sure that the phone number entered below belongs to you',
        id: 'Common.paymentPhoneNotice'
    },
    clickCheckConnectionQuality: {
        defaultMessage: 'Click to check connection quality',
        id: 'Common.clickCheckConnectionQuality'
    },
    requiredField: {
        id: 'Common.requiredField',
        defaultMessage: 'required field'
    },
    clickCallUsPhone: {
        defaultMessage: 'Click to call us by phone',
        id: 'Common.clickCallUsPhone'
    },
    quantity: {
        defaultMessage: 'pcs',
        id: 'Common.quantity'
    },
    clickSelectDevices: {
        defaultMessage: 'Click to select devices (microphone, camera, speaker)',
        id: 'Common.clickSelectDevices'
    },
    yes: {
        id: 'Common.yes',
        defaultMessage: 'Yes'
    },
    connection: {
        defaultMessage: 'Connection...',
        id: 'Common.connection'
    },
    camera: {
        defaultMessage: 'Camera',
        id: 'Common.camera'
    },
    reorderNumberTitle: {
        id: 'Common.reorderNumberTitle',
        defaultMessage: 'Additional order to order # <orderNumber></orderNumber>'
    },
    contactUs: {
        defaultMessage: 'Contact us',
        id: 'Common.contactUs'
    },
    devices: {
        defaultMessage: 'Devices',
        id: 'Common.devices'
    },
    downloadAppViber: {
        defaultMessage: 'Download Viber app',
        id: 'Common.downloadAppViber'
    },
    attemptsNoticeCode: {
        defaultMessage: 'Error when entering code, {attempts} attempts left.',
        id: 'Common.attemptsNoticeCode'
    },
    microphone: {
        defaultMessage: 'Microphone',
        id: 'Common.microphone'
    },
    attemptsNoticePassword: {
        defaultMessage: 'Incorrect password, {attempts} attempts left.',
        id: 'Common.attemptsNoticePassword'
    },
    selectDevices: {
        defaultMessage: 'Select devices',
        id: 'Common.selectDevices'
    },
    RussianFederation: {
        defaultMessage: 'Russian Federation',
        id: 'Common.RussianFederation'
    },
    speaker: {
        defaultMessage: 'Speaker',
        id: 'Common.speaker'
    },
    RepublicOfArmenia: {
        defaultMessage: 'Republic of Armenia',
        id: 'Common.RepublicOfArmenia'
    },
    testLine: {
        id: 'Common.testLine',
        defaultMessage: 'Testing the connection'
    },
    KyrgyzRepublic: {
        defaultMessage: 'Kyrgyz Republic',
        id: 'Common.KyrgyzRepublic'
    },
    video: {
        id: 'Common.video',
        defaultMessage: 'Video'
    },
    BELARUS: {
        defaultMessage: 'Belarus',
        id: 'Common.BELARUS'
    },
    viberHeader: {
        defaultMessage: 'Open a chat or download Viber',
        id: 'Common.viberHeader'
    },
    KAZAKHSTAN: {
        defaultMessage: 'Kazakhstan',
        id: 'Common.KAZAKHSTAN'
    },
    viberInApp: {
        defaultMessage: 'in the installed application',
        id: 'Common.viberInApp'
    },
    ARMENIA: {
        defaultMessage: 'Armenia',
        id: 'Common.ARMENIA'
    },
    viberOpenChat: {
        id: 'Common.viberOpenChat',
        defaultMessage: 'Open a chat with INVITRO RU'
    },
    KYRGYZSTAN: {
        defaultMessage: 'Kyrgyzstan',
        id: 'Common.KYRGYZSTAN'
    },
    RUSSIA: {
        defaultMessage: 'Russia',
        id: 'Common.RUSSIA'
    },
    unknownError: {
        id: 'Common.unknownError',
        defaultMessage: 'Please try again later. We apologize for any inconvenience caused!'
    },
    RepublicOfBelarus: {
        defaultMessage: 'Republic of Belarus',
        id: 'Common.RepublicOfBelarus'
    },
    RepublicOfKazakhstan: {
        defaultMessage: 'Republic of Kazakhstan',
        id: 'Common.RepublicOfKazakhstan'
    },
    phoneRegistrationNotice: {
        id: 'Common.phoneRegistrationNotice',
        defaultMessage: 'Registration and login to the profile are only possible for subscribers from these countries.'
    },
    UKRAINE: {
        defaultMessage: 'Ukraine',
        id: 'Common.UKRAINE'
    },
    addLater: {
        defaultMessage: 'Add later',
        id: 'Common.addLater'
    },
    INTERNAL_SERVER_ERROR: {
        id: 'Common.INTERNAL_SERVER_ERROR',
        defaultMessage: 'Something went wrong. Please try to repeat the action later. We apologize for the inconvenience!'
    },
    enterCodeFromMail: {
        defaultMessage: 'Enter the code from the letter',
        id: 'Common.enterCodeFromMail'
    },
    afterTime: {
        id: 'Common.afterTime',
        defaultMessage: 'after {time}'
    },
    phoneDoesNotExist: {
        defaultMessage: 'This phone does not exist',
        id: 'Common.phoneDoesNotExist'
    },
    cancel: {
        defaultMessage: 'Cancel',
        id: 'Common.cancel'
    },
    requestCodeAgain: {
        defaultMessage: 'Request code again',
        id: 'Common.requestCodeAgain'
    },
    electronicMail: {
        defaultMessage: 'Electronic mail',
        id: 'Common.electronicMail'
    },
    requestCodeAgainAfterTime: {
        defaultMessage: 'Request code again after {time}',
        id: 'Common.requestCodeAgainAfterTime'
    },
    enterCodeSMS: {
        defaultMessage: 'Enter the code from SMS',
        id: 'Common.enterCodeSMS'
    },
    weSentCodeEmail: {
        defaultMessage: 'We sent a 6-digit code by email ',
        id: 'Common.weSentCodeEmail'
    },
    buttonRepeat: {
        defaultMessage: 'Repeat',
        id: 'Common.buttonRepeat'
    },
    weSentCodePhone: {
        id: 'Common.weSentCodePhone',
        defaultMessage: 'We sent a 6-digit code to the number '
    },
    next: {
        defaultMessage: 'Continue',
        id: 'Common.next'
    },
    requestSMSCodeAgain: {
        id: 'Common.requestSMSCodeAgain',
        defaultMessage: 'Request SMS code again'
    },
    register: {
        defaultMessage: 'Register',
        id: 'Common.register'
    },
    requestSMSCodeAgainAfterTime: {
        id: 'Common.requestSMSCodeAgainAfterTime',
        defaultMessage: 'Request SMS code again after {time}'
    },
    registration: {
        defaultMessage: 'Registration',
        id: 'Common.registration'
    },
    serverErrorConflictDescription: {
        defaultMessage: 'Please try again.',
        id: 'Common.serverErrorConflictDescription'
    },
    serverErrorConflictTitle: {
        defaultMessage: 'Oops, something went wrong \uD83D\uDE1E',
        id: 'Common.serverErrorConflictTitle'
    }
});
