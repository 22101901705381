import { CityFilterType } from 'constants/cityFilterType';
import {
    CHECKUP_SHOW_CHANGE_CITY_FORM,
    CLEAR_ACTIVE_CHECKUP_CITY,
    FETCH_LIST_CITY,
    SET_ACTIVE_CHECKUP_CITY,
    SET_ACTIVE_CITY,
    SET_FILTER,
    SHOW_CHANGE_CITY_FORM,
    SHOW_CITY_PANEL,
    UPDATE_CITY_LETTERS,
    UPDATE_COUNTRY_CODE
} from 'redux/cities/actions';
import { COUNT_SEARCH_RESULTS, SUPPORTED_TERRITORIES } from 'redux/cities/constants';
import { basicReducer } from 'redux/helpers';
import { City } from 'types/common';
import { Store } from 'types/store';

const initialState: Store.Cities.State = {
    activeCity: {
        id: null,
        name: null,
        capital: false,
        region: null,
        showRegion: false,
        territory: ''
    },
    activeCheckupCity: {
        id: null,
        name: null,
        capital: false,
        region: null,
        showRegion: false,
        territory: ''
    },
    cityLetters: [],
    entities: {},
    fetchAll: {},
    filter: {
        letter: null,
        searchString: '',
        territory: null
    },
    isShowCityPanel: false,
    loading: false,
    outputListCity: [],
    showChangeCityForm: false,
    showChangeCityFormCheckup: false
};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SHOW_CITY_PANEL.BASE: {
            return {
                ...state,
                filter: {
                    letter: null,
                    searchString: '',
                    territory: state.activeCity.territory
                },
                isShowCityPanel: action.meta
            };
        }
        case SHOW_CHANGE_CITY_FORM.BASE: {
            return {
                ...state,
                filter: {
                    letter: null,
                    searchString: '',
                    territory: state.activeCity.territory
                },
                outputListCity: [],
                showChangeCityForm: action.meta
            };
        }
        case CHECKUP_SHOW_CHANGE_CITY_FORM.BASE: {
            return {
                ...state,
                filter: {
                    letter: null,
                    searchString: '',
                    territory: state.activeCity.territory
                },
                outputListCity: [],
                showChangeCityFormCheckup: action.meta
            };
        }
        case SET_FILTER.BASE: {
            const type = action?.meta?.type;
            const param = action?.meta?.param;
            let filteredCities;
            switch (type) {
                case CityFilterType.TERRITORY:
                    if (state.filter.territory === param) {
                        return state;
                    }
                    filteredCities = state.entities[param];
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            letter: null,
                            territory: param
                        },
                        outputListCity: [...filteredCities]
                    };
                case CityFilterType.LETTER:
                    filteredCities = state.entities[state.filter.territory as string].filter((city) => city.name?.charAt(0) === param);
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            letter: param
                        },
                        outputListCity: [...filteredCities]
                    };
                case CityFilterType.SEARCH_STRING:
                    if (!param.length) {
                        return {
                            ...state,
                            filter: {
                                ...state.filter,
                                searchString: param
                            }
                        };
                    }
                    filteredCities = state.entities[state.filter.territory as string]
                        .filter((city) => city.name && city.name.toUpperCase().indexOf(param.toUpperCase()) >= 0)
                        .splice(0, COUNT_SEARCH_RESULTS);
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            letter: null,
                            searchString: param
                        },
                        outputListCity: [...filteredCities]
                    };
                default:
                    return state;
            }
        }

        case FETCH_LIST_CITY.STARTED:
        case FETCH_LIST_CITY.SUCCEEDED:
        case FETCH_LIST_CITY.ERRORED: {
            let entities = state.entities;
            if (action.type === FETCH_LIST_CITY.SUCCEEDED) {
                const cities = action.payload.data;
                const hostnameTerritory = state.activeCity.territory;

                const territorySet: any = new Set(
                    cities
                        .filter((city: City) => {
                            if (hostnameTerritory === SUPPORTED_TERRITORIES.CRIMEA) {
                                // Если открыт ЛК Крыма, то возвращаем только Крым
                                return city.territory === SUPPORTED_TERRITORIES.CRIMEA;
                            } else {
                                // Иначе возвращаем все территории кроме Крыма
                                return city.territory !== SUPPORTED_TERRITORIES.CRIMEA && city.territory !== SUPPORTED_TERRITORIES.UKRAINE;
                            }
                        })
                        .map((city: City) => city.territory)
                );

                const territories = [...territorySet];

                entities = territories.reduce((map, territory) => {
                    const territoryCities = cities
                        .filter((city: City) => city.territory === territory)
                        .sort(function (a: { name: string }, b: { name: string }) {
                            return a?.name?.localeCompare(b?.name);
                        });
                    map[territory] = postProcessCities(territoryCities);
                    return map;
                }, {});
            }
            return {
                ...state,
                entities,
                fetchAll: basicReducer(state.fetchAll, action)
            };
        }
        case UPDATE_CITY_LETTERS.BASE: {
            if (action.meta) {
                const filterLetters = new Set();
                action.meta.forEach((city: City) => filterLetters.add(city.name?.charAt(0)));
                const sortedFilterLetters = Array.from(filterLetters).sort();
                return {
                    ...state,
                    cityLetters: [...sortedFilterLetters]
                };
            }
            break;
        }
        case SET_ACTIVE_CITY.BASE: {
            const activeCity = action.meta;
            return {
                ...state,
                activeCity,
                homeOffice: {}
            };
        }
        case SET_ACTIVE_CHECKUP_CITY.BASE: {
            const activeCheckupCity = action.meta;
            return {
                ...state,
                activeCheckupCity,
                homeOffice: {}
            };
        }
        case CLEAR_ACTIVE_CHECKUP_CITY.BASE: {
            return {
                ...state,
                activeCheckupCity: { ...initialState.activeCheckupCity }
            };
        }
        case UPDATE_COUNTRY_CODE.BASE: {
            const { currentCountryCode } = action.meta;
            return {
                ...state,
                currentCountryCode
            };
        }
        default:
            return state;
    }
}

const postProcessCities = (cities: City[]) => {
    const groupedByName: { [key: string]: City[] } = {};
    cities.forEach((city) => {
        const groupedCities: any = groupedByName[city.name as string] || [];
        groupedCities.push(city);
        groupedByName[city.name as string] = groupedCities;
    });
    for (const groupedCities of Object.values(groupedByName)) {
        if (groupedCities.length > 1) {
            groupedCities.forEach((city) => (city.showRegion = true));
        }
    }
    return cities;
};
