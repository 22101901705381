import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import { SUPPORTED_LOCALES } from 'redux/i18n/constants';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';

import appStoreIconEn from './appStoreIconEn.svg';
import appStoreIconRu from './appStoreIconRu.svg';
import googlePlayIconEn from './googlePlayIconEn.svg';
import googlePlayIconRu from './googlePlayIconRu.svg';
import styles from './IconMobileApp.module.css';

type TProps = {
    link: string;
    type?: string;
    locale: any;
};

const IconMobileApp: FC<TProps> = ({ link, type, locale }) => {
    const renderIcon = () => {
        if (type === 'GOOGLE') {
            switch (locale) {
                case SUPPORTED_LOCALES.RUSSIAN: {
                    return googlePlayIconRu;
                }
                case SUPPORTED_LOCALES.ENGLISH: {
                    return googlePlayIconEn;
                }
                /*
                case SUPPORTED_LOCALES.UKRAINIAN: {
                    return googlePlayIconUk;
                }
                */
                default: {
                    return '';
                }
            }
        }

        if (type === 'APPLE') {
            switch (locale) {
                case SUPPORTED_LOCALES.RUSSIAN: {
                    return appStoreIconRu;
                }
                case SUPPORTED_LOCALES.ENGLISH: {
                    return appStoreIconEn;
                }
                /*
                case SUPPORTED_LOCALES.UKRAINIAN: {
                    return appStoreIconEn;
                }
                */
                default: {
                    return '';
                }
            }
        }
    };

    if (link) {
        return (
            <React.Fragment>
                <a href={link} className={styles.link} target={'_blank'} rel='noopener noreferrer'>
                    <img className={styles.icon} alt='' src={renderIcon()} />
                </a>
            </React.Fragment>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: any) => {
    return {
        locale: state.i18n.currentLocale,
        territorySettings: currentTerritorySettingsSelector(state)
    };
};

export default memo(connect(mapStateToProps, null)(IconMobileApp));
