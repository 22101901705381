import { useAppDispatch, useAppSelector } from 'app/Hooks';
import ButtonLink from 'components/button/link/ButtonLink';
import InputSplitted from 'components/input/splitted/InputSplitted';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Timer from 'components/timer/Timer';
import { LoginType } from 'constants/loginType';
import commonMessages from 'messages/CommonMessages';
import styles from 'page/auth/Common.module.css';
import ServerError from 'page/auth/components/cards/ServerError';
import Error from 'page/auth/components/error/Error';
import messages from 'page/auth/login/LoginConfirmPage/LoginConfirmPageMessages';
import Instruction from 'page/auth/reactivate/Instruction';
import PageLayout from 'page/layout/PageLayout';
import React, { FC, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { recovery, recoveryConfirmByCode as handleRecoveryConfirmByCode } from 'redux/auth/actions';
import { WRONG_CODE } from 'redux/auth/errorCodes';
import { recoveryConfirmByCodeSelector, recoverySelector } from 'redux/auth/selectors';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { getTimeToResend } from 'utils/timeUtils';

const RecoveryConfirmPage: FC = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();

    const recoveryData = useAppSelector(recoverySelector);
    const territory = useAppSelector(currentTerritorySelector);
    const recoveryConfirmByCode = useAppSelector(recoveryConfirmByCodeSelector);

    useEffect(() => {
        document.title = formatMessage(messages.pageTitle);
    }, []);

    useEffect(() => {
        const { recoveryToken } = recoveryConfirmByCode.response || {};

        if (recoveryToken) {
            navigate('/recovery', { state: { recoveryToken } });
        }
    }, [recoveryConfirmByCode]);

    const getAction = (code: string) => {
        const { confirmedLoginToken } = state || {};

        return dispatch(handleRecoveryConfirmByCode({ code, confirmedLoginToken }));
    };

    const renderContent = () => {
        const { loginType, login, confirmedLoginToken } = state || {};

        return (
            <>
                <Instruction loginType={loginType} login={login} />
                <InputSplitted length={6} error={recoveryConfirmByCode.error} done={(code) => getAction(code)} />
                <Timer seconds={getTimeToResend(recoveryData, recoveryConfirmByCode)}>
                    {({ formatted, expired }: any) => (
                        <ButtonLink
                            className={styles.resend}
                            disabled={!expired}
                            onClick={() => dispatch(recovery({ confirmedLoginToken: confirmedLoginToken, territory }))}
                        >
                            {!expired
                                ? loginType === LoginType.PHONE
                                    ? formatMessage(commonMessages.requestSMSCodeAgainAfterTime, { time: formatted })
                                    : formatMessage(commonMessages.requestCodeAgainAfterTime, { time: formatted })
                                : null}
                            {expired
                                ? loginType === LoginType.PHONE
                                    ? formatMessage(commonMessages.requestSMSCodeAgain)
                                    : formatMessage(commonMessages.requestCodeAgain)
                                : null}
                        </ButtonLink>
                    )}
                </Timer>
                <Error error={[recoveryData.error, recoveryConfirmByCode.error]} attemptsNotice={commonMessages.attemptsNoticeCode} />
            </>
        );
    };

    return (
        <PageLayout
            main={
                <div className={styles.main}>
                    <LoaderModal show={recoveryConfirmByCode.loading} />
                    <div className={styles.container}>
                        <ServerError errors={[recoveryData.error, recoveryConfirmByCode.error]} statuses={[WRONG_CODE]}>
                            <>{!recoveryConfirmByCode.success && renderContent()}</>
                        </ServerError>
                    </div>
                </div>
            }
        />
    );
};

export default memo(RecoveryConfirmPage);
