//@ts-nocheck
import { createBrowserHistory } from 'history';
import useRecaptchaV3 from 'hooks/useRecaptcha';
import React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const history = createBrowserHistory();

export default function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const [searchParams] = useSearchParams(new URLSearchParams(window.location.search));
        const recaptchaV3 = useRecaptchaV3();

        return (
            <Component
                history={{ ...history, goBack: () => navigate(-1), push: navigate, replace: navigate }}
                {...props}
                location={location}
                params={params}
                searchParams={searchParams}
                match={{ ...params, params }}
                navigate={navigate}
                recaptchaV3={{ clear: recaptchaV3.clear, execute: recaptchaV3.execute, init: recaptchaV3.init }}
            />
        );
    }

    return ComponentWithRouterProp;
}
