import { TerritoryType } from 'constants/territoryType';

export const CITY_GUID_COOKIE_KEY = 'INVITRO_CITY_LK_GUID';
export const INVITRO_LKP_COUNTRY_CODE = 'INVITRO_LKP_COUNTRY_CODE';
export const COUNT_SEARCH_RESULTS = 10;

export const SUPPORTED_TERRITORIES = {
    ARMENIA: TerritoryType.ARMENIA,
    BELARUS: TerritoryType.BELARUS,
    CRIMEA: TerritoryType.CRIMEA,
    KAZAKHSTAN: TerritoryType.KAZAKHSTAN,
    KYRGYZSTAN: TerritoryType.KYRGYZSTAN,
    RUSSIA: TerritoryType.RUSSIA,
    UKRAINE: TerritoryType.UKRAINE
};

export const TERRITORIES_BY_DOMAIN = {
    am: SUPPORTED_TERRITORIES.ARMENIA,
    by: SUPPORTED_TERRITORIES.BELARUS,
    kg: SUPPORTED_TERRITORIES.KYRGYZSTAN,
    kz: SUPPORTED_TERRITORIES.KAZAKHSTAN,
    ru: SUPPORTED_TERRITORIES.RUSSIA,
    su: SUPPORTED_TERRITORIES.CRIMEA,
    ua: SUPPORTED_TERRITORIES.RUSSIA
};

export const COUNTRY_CODES_BY_TERRITORY = {
    [SUPPORTED_TERRITORIES.RUSSIA]: 'RU',
    [SUPPORTED_TERRITORIES.CRIMEA]: 'SU',
    [SUPPORTED_TERRITORIES.BELARUS]: 'BY',
    [SUPPORTED_TERRITORIES.KAZAKHSTAN]: 'KZ',
    [SUPPORTED_TERRITORIES.UKRAINE]: 'UA',
    [SUPPORTED_TERRITORIES.ARMENIA]: 'AM',
    [SUPPORTED_TERRITORIES.KYRGYZSTAN]: 'KG'
};

export const LK_CODES_BY_TERRITORY = {
    [SUPPORTED_TERRITORIES.RUSSIA]: 'LK_RU',
    [SUPPORTED_TERRITORIES.CRIMEA]: 'LK_SU',
    [SUPPORTED_TERRITORIES.BELARUS]: 'LK_BY',
    [SUPPORTED_TERRITORIES.KAZAKHSTAN]: 'LK_KZ',
    [SUPPORTED_TERRITORIES.UKRAINE]: 'LK_UA',
    [SUPPORTED_TERRITORIES.ARMENIA]: 'LK_AM',
    [SUPPORTED_TERRITORIES.KYRGYZSTAN]: 'LK_KG'
};
