//@ts-nocheck
export function getClientData(data) {
    return data
        ? {
              customField: data.customField ?? '',
              email: data.email,
              name: data.nickname,
              personalManager: data.personalManager ?? '',
              phone: data.phone
          }
        : '';
}

export function getChatLinks(arr, type) {
    return arr?.find((a) => a.type === type)?.uri;
}

export function chatButtonWithRecaptcha() {
    const chatUp = 'chat-up';
    const chatContainer = window.top.document.getElementById('chat-container');
    if (chatContainer) {
        chatContainer.classList.add(chatUp);
        chatContainer.style.bottom = '74px !important';
    }
}

export function chatButtonWithoutRecaptcha() {
    const style = document.getElementById('chat-up');
    if (style) {
        style.remove();
    }
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function setC2CConfig(authenticated, user) {
    if (c2c_config) {
        delete c2c_config.callerDN;
        c2c_config.callerDN = authenticated ? user?.phone || user?.email : 'Anonim';
    }
}

export const setMultibuttonScript = (multiButtonEnabled) => {
    if (multiButtonEnabled) {
        document.getElementById('multibutton')?.remove();
        const script = document.createElement('script');
        script.id = 'multibutton';
        script.src = `/assets/multibutton.js?rnd=${Math.random()}`;
        document.body.appendChild(script);
        script.onload = () => window.initMultiButton();
        script.onerror = (event) => {
            console.error(event);
        };
    }
};

export const setEdnaScript = (settings, user) => {
    !(function (e, n) {
        'use strict';
        function a(e, t) {
            const n = setInterval(function () {
                ThreadsWidget && !ThreadsWidget.isDummy && (clearInterval(n), ThreadsWidget[e] && ThreadsWidget[e].apply(void 0, t));
            }, 100);
        }
        (window.ThreadsWidget = {}),
            (e = e || '/settings.json'),
            (ThreadsWidget.isDummy = !0),
            ['hideInvite', 'version', 'commitHash', 'showChat', 'hideChat', 'onHideChat', 'onScenarios', 'onLoad', 'on', 'reInit', 'init', 'unInit'].forEach(
                (t) => {
                    ThreadsWidget[t] = (...e) => {
                        a(t, e);
                    };
                }
            );
        i = new XMLHttpRequest();
        let i,
            t = function (e, t, n, a) {
                (i.onreadystatechange = function () {
                    if (4 === i.readyState)
                        if (200 === this.status) n(i.response);
                        else {
                            if ('function' != typeof a) throw new Error(i.response);
                            a(i);
                        }
                }),
                    i.open(e, t),
                    i.send();
            };
        function o(t) {
            t.webchat && (t.webchat.filename = t.filename),
                n && 'string' == typeof n && (t.webchat.currentLocale = n),
                n &&
                    'object' == typeof n &&
                    (n.locale && 'string' == typeof n.locale && (t.webchat.currentLocale = n.locale),
                    n.unavailable && 'boolean' == typeof n.unavailable && (t.webchat.isUnavailableOnStart = n.unavailable));
            try {
                sessionStorage.setItem('__threadsWidget', JSON.stringify(t.webchat));
            } catch (e) {
                window.__threadsWidget = JSON.stringify(t.webchat);
            }
            let e;
            t.filename
                ? (((e = document.createElement('script')).type = 'text/javascript'),
                  (e.src = t.filename),
                  (e.id = 'threadswidget'),
                  // eslint-disable-next-line no-cond-assign
                  (t = document.getElementsByTagName('script')[0]) ? t.parentNode.insertBefore(e, t) : document.body.appendChild(e))
                : console.error('Invalid bundle');
        }
        function s() {
            if (settings) {
                window.clientId = user?.id || '';
                window.clientData = getClientData(user);
                o(settings);
            } else {
                t('GET', e + '?rnd=' + Math.random(), function (e) {
                    const t = JSON.parse(e);
                    o(t);
                });
            }
        }
        s();
    })('settings.json', {});
};

export const removeEdnaScript = () => {
    delete window.clientId;
    delete window.clientData;
    document.querySelector('#__threadswidget_chat__container')?.remove();
    document.querySelector('#threadswidget')?.remove();
};
