import { useAppDispatch, useAppSelector } from 'app/Hooks';
import Button from 'components/button/Button';
import ButtonSecondary from 'components/button/secondary/ButtonSecondary';
import FormField from 'components/form/field/FormField';
import InputDate from 'components/input/date/InputDate';
import Input from 'components/input/Input';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Modal from 'components/modal/Modal';
import ModalSuccess from 'components/modal/success/ModalSuccess';
import InputPhone from 'containers/input/phone/InputPhone';
import commonMessages from 'messages/CommonMessages';
import messages from 'page/partial/result/add/AddResultDialogMessages';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CITY_GUID_COOKIE_KEY } from 'redux/cities/constants';
import { localeSelector } from 'redux/i18n/selectors';
import { addResult, clearAddResult } from 'redux/result/actions';
import { addResultSelector } from 'redux/result/selectors';
import { InputPhoneData, IPatient } from 'types/common';
import { getCookie } from 'utils/browserUtils';
import { chatButtonWithoutRecaptcha, chatButtonWithRecaptcha } from 'utils/chatUtils';
import FC_FormHelper from 'utils/FC_FormHelper';
import { formatISODate, parseISO } from 'utils/timeUtils';
import { validateEmail, validateInz, validateNotBlank, validatePhoneNumber } from 'utils/validators';

import styles from './AddResultDialog.module.css';

declare const window: any;

type TProps = {
    title?: any;
    description?: any;
    modal?: boolean;
    patient?: IPatient;
    show: boolean;
    onClose?: (value?: boolean) => void;
    renderError?: () => void;
};

type TState = {
    orderNumber: string;
    birthday: Date | string;
    lastName: string;
    firstName?: string;
    email?: string;
    phone?: string;
    inputPhone?: InputPhoneData;
    errors: any;
    showSuccess: boolean;
    additionalFields: [];
    key?: string;
};

interface KeyboardEvent {
    keyCode: number;
}

const parseBirthday = (birthday: Date | string) => {
    return birthday ? parseISO(birthday) : '';
};

const initialState = (patient?: IPatient): TState => {
    return {
        additionalFields: [],
        birthday: patient ? parseBirthday(patient?.birthday) : '',
        errors: {},
        lastName: patient ? patient?.lastName : '',
        orderNumber: '',
        showSuccess: false
    };
};

const AddResultDialog: FC<TProps> = ({ title, description, patient, show, onClose, modal = true, renderError }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { loading, success, error } = useAppSelector(addResultSelector);
    const { formatMessage } = useIntl();
    const { locale } = useAppSelector((state) => localeSelector(state));
    const [state, setState] = useState<TState>(initialState(patient));

    useEffect(() => {
        if (show) {
            document.addEventListener('keydown', onEscapeHandler);

            if (patient) {
                setState(initialState(patient));
            }

            dispatch(clearAddResult());
            chatButtonWithRecaptcha();
        } else {
            document.removeEventListener('keydown', onEscapeHandler);
            chatButtonWithoutRecaptcha();
        }
    }, [show]);

    useEffect(() => {
        if (success) {
            window.dataLayer.push({
                action_cat: 'lk3',
                action_name: 'orders',
                action_param: 'add_result/submit',
                eNI: '0',
                event: 'addEvents_makeActions'
            });

            setState((prevState) => ({ ...prevState, showSuccess: true }));
        }
    }, [success]);

    useEffect(() => {
        const state: any = {};

        if (error?.errors) {
            const errorsState: any = {};
            error.errors.forEach((error: any) => (errorsState[error.field] = true));
            const additionalFields = state.additionalFields || [];

            if (additionalFields?.length === 0) {
                error.errors
                    .filter((fieldError: any) => Object.keys(additionalFieldsData).includes(fieldError.field))
                    .forEach((fieldError: any) => additionalFields.push(fieldError.field));
            }
            state.key = error.key;
            state.errors = errorsState;
            state.additionalFields = additionalFields;
        }

        if (Object.keys(state).length > 0) {
            setState((prevState) => ({ ...prevState, ...state }));
        }
    }, [loading, error]);

    const validators: any = {
        birthday: validateNotBlank,
        lastName: validateNotBlank,
        orderNumber: validateInz
    };

    const formHelper = new FC_FormHelper(state, setState, error, validators);

    const additionalFieldsData: any = {
        email: {
            component: (parentState: any) => {
                return (
                    <FormField
                        className={styles.searchFormBlockItem}
                        key='email'
                        label={formatMessage(commonMessages.email)}
                        children={
                            <Input
                                name='email'
                                value={parentState.email}
                                onChange={formHelper.handleValueChange}
                                error={parentState?.errors?.email}
                                disabled={loading}
                            />
                        }
                    />
                );
            },
            validator: (email: string) => validateEmail(email)
        },
        firstName: {
            component: (parentState: any) => {
                return (
                    <FormField
                        className={styles.searchFormBlockItem}
                        key='firstName'
                        label={formatMessage(commonMessages.firstNameLabel)}
                        children={
                            <Input
                                name='firstName'
                                value={parentState.firstName}
                                onChange={formHelper.handleValueChange}
                                error={parentState?.errors?.firstName}
                                disabled={loading}
                            />
                        }
                    />
                );
            },
            validator: validateNotBlank
        },
        inputPhone: {
            component: (parentState: any) => {
                return (
                    <InputPhone
                        name='phone'
                        value={parentState.inputPhone?.value}
                        onChange={(inputPhone) => setState((prevState: any) => ({ ...prevState, inputPhone }))}
                    />
                );
            },
            validator: (phone: string) => validatePhoneNumber(phone)
        },
        phone: {
            component: (parentState: any) => {
                return (
                    <FormField
                        className={styles.searchFormBlockItem}
                        key='phone'
                        label={formatMessage(commonMessages.phone)}
                        children={
                            <Input
                                name='phone'
                                value={parentState.phone}
                                onChange={formHelper.handleValueChange}
                                error={parentState?.errors?.phone}
                                disabled={loading}
                            />
                        }
                    />
                );
            },
            validator: (phone: string) => validatePhoneNumber(phone)
        }
    };

    for (const [key, value] of Object.entries(additionalFieldsData) as any) {
        validators[key] = value.validator;
    }

    const onEscapeHandler = (event: KeyboardEvent) => {
        if (event.keyCode === 27 && onClose) {
            onClose();
        }
    };

    const viewResult = () => {
        const { key, lastName } = state;
        navigate(`/results?${queryString.stringify({ key, lastName })}`);
    };

    const onShowSuccessClose = () => {
        if (state.showSuccess && onClose) {
            setState((prevState) => ({ ...prevState, showSuccess: false }));
            onClose(true);
        }
    };

    const renderAdditionalFields = () => {
        const { additionalFields } = state;
        if (additionalFields?.length === 0) {
            return null;
        }
        return (
            <Fragment>
                {modal && <p className={styles.additionalFieldsNote}>{formatMessage(messages.additionalFieldsNote)}</p>}
                {additionalFields?.map((field) => {
                    if (field === 'phone' && !modal) {
                        return (
                            <InputPhone
                                key='phone'
                                name='phone'
                                value={state.inputPhone?.value}
                                onChange={(inputPhone) => setState((prevState: any) => ({ ...prevState, inputPhone }))}
                            />
                        );
                    }

                    return additionalFieldsData[field].component({ parentState: state });
                })}
            </Fragment>
        );
    };

    const isValidForm = () => {
        return formHelper.isValid([...state.additionalFields, 'lastName', 'birthday', 'orderNumber']);
    };

    const handleAddResult = async () => {
        const phone = state.inputPhone?.number || state.phone;
        const { lastName, birthday, orderNumber, firstName, email, additionalFields } = state;

        const cityId = getCookie(CITY_GUID_COOKIE_KEY);
        if (formHelper.validate([...additionalFields, 'lastName', 'birthday', 'orderNumber'])) {
            dispatch(
                addResult({
                    birthday: formatISODate(birthday),
                    cityId,
                    email,
                    firstName,
                    lastName,
                    orderNumber,
                    phone
                })
            );
        }
    };

    const renderForm = () => {
        return (
            <div className={styles.container}>
                <LoaderModal show={loading} />

                <ModalSuccess message={formatMessage(messages.success)} show={state.showSuccess && modal} onClose={() => onShowSuccessClose()} />

                <h2>{title ? title : formatMessage(messages.title)}</h2>
                {description && <div className={styles.description}>{description}</div>}

                {!renderError && formHelper.renderError()}

                <div className={styles.searchFormBlock}>
                    <FormField
                        className={styles.searchFormBlockItem}
                        tip={formatMessage(messages.orderNumberNote)}
                        label={formatMessage(messages.orderNumberLabel)}
                        children={
                            <Input
                                name='orderNumber'
                                placeholder={formatMessage(messages.orderNumberPlaceholder)}
                                value={state.orderNumber}
                                onChange={formHelper.handleValueChange}
                                error={state.errors.orderNumber}
                                disabled={loading}
                            />
                        }
                    />
                    <FormField
                        className={styles.searchFormBlockItem}
                        label={formatMessage(commonMessages.birthdayLabel)}
                        children={
                            <InputDate
                                name='birthday'
                                selected={state.birthday}
                                onChange={(date) =>
                                    formHelper.handleValueChange({
                                        target: {
                                            name: 'birthday',
                                            value: date
                                        }
                                    })
                                }
                                locale={locale}
                                error={state.errors.birthday}
                                disabled={loading}
                            />
                        }
                    />
                    <FormField
                        className={styles.searchFormBlockItem}
                        tip={formatMessage(messages.lastNameNote)}
                        label={formatMessage(commonMessages.lastNameLabel)}
                        children={
                            <Input
                                name='lastName'
                                placeholder={formatMessage(commonMessages.lastNamePlaceholder)}
                                value={state.lastName}
                                onChange={formHelper.handleValueChange}
                                error={state.errors.lastName}
                                disabled={loading}
                            />
                        }
                    />
                    {renderAdditionalFields()}
                </div>
                {renderError ? renderError() : null}
                <div className={styles.buttonsBlock}>
                    <Button disabled={!isValidForm()} className={styles.button} text={formatMessage(messages.addBtn)} onClick={() => handleAddResult()} />
                    {state.additionalFields.length !== 0 && modal ? (
                        <ButtonSecondary className={styles.button} text={formatMessage(commonMessages.view)} onClick={() => viewResult()} />
                    ) : null}
                </div>
            </div>
        );
    };

    return modal ? (
        <Modal updateBodyStyle={false} closeOutside={false} show={show} closable={true} onClose={() => onClose && onClose()} children={renderForm()} />
    ) : (
        renderForm()
    );
};

export default AddResultDialog;
