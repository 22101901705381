import './react-datepicker.css';

import { maskitoInitialCalibrationPlugin, MaskitoMaskExpression, MaskitoOptions } from '@maskito/core';
import Input from 'components/input/Input';
import inputStyles from 'components/input/Input.module.css';
import useLocalization from 'hooks/useLocalization';
import React, { FC, forwardRef, memo, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import styles from './InputDate.module.css';
import messages from './InputDateMessages';

type TInputWrapperProps = {
    onChange: any;
    value?: string;
    locale: string;
    disabled?: boolean;
    setOpen: (value: boolean) => void;
};

const masks: Record<string, MaskitoMaskExpression> = {
    EN: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    RU: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
};

const InputWrapper: FC<TInputWrapperProps> = forwardRef(({ onChange, value, locale, setOpen, ...rest }, ref) => {
    const [maskOptions, setMaskOptions] = useState<MaskitoOptions>({ mask: masks[locale.toUpperCase()], plugins: [maskitoInitialCalibrationPlugin()] });

    useEffect(() => {
        setMaskOptions({ mask: masks[locale.toUpperCase()], plugins: [maskitoInitialCalibrationPlugin()] });
    }, [locale]);

    return (
        <div className={styles.container}>
            <Input ref={ref} maskOptions={maskOptions} onInput={onChange} value={value} {...rest} />
            <span onClick={() => setOpen(true)} className={styles.icon} />
        </div>
    );
});

type TInputDateProps = {
    value?: string;
    error?: boolean;
    isClearable?: boolean;
    locale: string;
    onChange: (event?: any) => void;
    maxDate?: Date | null;
    minDate?: Date | null;
    name?: string;
    selected?: any;
    disabled?: boolean;
    calendarClassName?: string;
    placeholderText?: string;
    onBlur?: () => void;
    onSelect?: (date: Date) => void;
};

const InputDate: FC<TInputDateProps> = (props) => {
    const ref = useRef<any>();
    const format = useLocalization();
    const { value, onChange, locale, error, isClearable, minDate = new Date(1901, 0, 1), maxDate = new Date(), ...rest } = props;
    return (
        <DatePicker
            ref={ref}
            value={value}
            locale={locale}
            dateFormat='P'
            minDate={minDate}
            maxDate={maxDate}
            showYearDropdown
            showMonthDropdown
            dropdownMode='select'
            onChange={onChange}
            showPopperArrow={false}
            className={error ? inputStyles.error : ''}
            onChangeRaw={() => isClearable && onChange()}
            placeholderText={`${format(messages, 'placeholder')}`}
            customInput={<InputWrapper onChange={onChange} value={value} setOpen={ref?.current?.setOpen} locale={locale} />}
            {...rest}
        />
    );
};

export default memo(InputDate);
